import React, {Component, Suspense} from "react";
import queryString from 'query-string'
import {Input, Button, Card, Form} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import {withRouter} from "react-router-dom";
import {CheckCircleOutlined} from '@ant-design/icons';
import axios from 'axios';

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            params: queryString.parse(this.props.location.search),
            isSuccess: false
        }
    }

    onFinish = values => {

        const customAxios = axios.create({
            baseURL: 'https://backend.quieromenu.com/1',
            headers: {'X-Requested-With': 'XMLHttpRequest'}
        });

        const params = new URLSearchParams();
        params.append('username',this.state.params.username);
        params.append('token',this.state.params.token);
        params.append('new_password',values.password);

        customAxios.post('/apps/efK71cod9vSrKBwa9LQ06T351opxkeC3/request_password_reset', params)
            .then( () =>{
                this.setState({
                    isSuccess: true
                });
            })
            .catch((error) => {
                this.props.history.push(`/invalid-link`);
            });

    };

    render() {
        return <div className={"qm-container"}>
            <Suspense fallback={<div>Cargando...</div>}>
                <Card style={{margin: '20px'}}>
                    {!this.state.isSuccess ?
                        <div>
                            <h2 style={{marginBottom: 0, paddingBottom: 0}}>Cambia tu contraseña para el correo:</h2>
                            <p>{this.state.params.username}</p>
                            <Form onFinish={this.onFinish}>
                                <Form.Item
                                    style={{display: 'block'}}
                                    label="Nueva contraseña"
                                    name="password"
                                    hasFeedback
                                    rules={[{required: true, message: 'Introduce una contraseña'}, {
                                        min: 6,
                                        message: 'La contraseña debe tener al menos 6 letras'
                                    },]}
                                >
                                    <Input.Password placeholder="Escribe una contraseña..."/>
                                </Form.Item>
                                <Form.Item
                                    style={{display: 'block'}}
                                    label="Repite la nueva contraseña"
                                    name="password-repeat"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor, confirma tu nueva contraseña',
                                        },
                                        ({getFieldValue}) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Las dos contraseñas que has introducido no coinciden');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="Escribe una contraseña..."
                                        iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button style={{marginTop: '20px'}} type="primary" block htmlType="submit">
                                        Cambiar contraseña
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div> : <ResetPasswordSuccess/>}
                </Card>
            </Suspense>
        </div>;
    }

}

const ResetPasswordSuccess = () => {
    return (
        <div style={{width: "100%", textAlign: "center",}}>
            <CheckCircleOutlined style={{fontSize: '80px', color: 'green', marginBottom: '20px'}}/>
            <h2>Has cambiado la contraseña con éxito</h2>
            <p style={{textAlign: "center"}}>
                Tu contraseña ha sido modificada, ya puedes acceder a Quiero Menú con tu nueva contraseña
            </p>
            <Button
                style={{
                    marginTop: "20px"
                }}
                href={"https://app.quieromenu.com"}
                type="primary"
            >
                Continuar
            </Button>
        </div>
    );
};

export default withRouter(ResetPassword)
