import React, {Component} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./Home";
import "./main.less";
import "./banner.less";
import {Layout, Menu} from "antd";
import SmartBanner from "react-smartbanner";
import MenuDetail from "./menu/MenuDetail";
import RestaurantDetail from "./RestaurantDetail";
import ScrollToTop from "./utils/ScrollToTop";
import ResetPassword from "./resetPassword/ResetPassword";
import InvalidLink from "./invalidLink/InvalidLink";
import {NotFoundPage} from "./404/NotFoundPage";

const {Header, Content, Footer} = Layout;
let Parse = require("parse");

Parse.initialize("efK71cod9vSrKBwa9LQ06T351opxkeC3");
Parse.serverURL = "https://backend.quieromenu.com/1/";

export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showToolbar: true
        };
    }


    hideToolbar = () =>{
        this.setState({
            showToolbar:false
        });
    };

    render() {
        return (
            <Layout>
                <SmartBanner
                    position="top"
                    daysHidden={1}
                    daysReminder={7}
                    price={{ios: "Quiero Menú", android: "Quiero Menú"}}
                    button="Descargar"
                    storeText={{
                        ios: "App Store",
                        android: "Play Store"
                    }}
                    appStoreLanguage="es"
                    title="Mucho más sencillo"
                />

                <Layout
                    style={{
                        minHeight: "100vh",
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    {this.state.showToolbar?
                    <Header
                        style={{
                            textAlign: "center",
                            backgroundColor: "#E01C2C",
                            zIndex: 1,
                            width: "100%",
                        }}
                    >
                        <a
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%"
                            }}
                            href={"/"}
                        >
                            <img
                                style={{maxHeight: "30px"}}
                                src="https://quieromenu.com/wp-content/uploads/2019/10/logo_typeface-300x60.png"
                            />
                        </a>

                    </Header>:''}

                    <Content style={{flexGrow: "1", paddingBottom: "2em"}}>

                        <Router>
                            <ScrollToTop/>
                            <Switch>
                                <Route exact path="/" component={Home}/>
                                <Route exact path="/establecimiento/:id" render={(props) => <RestaurantDetail {...props} hideToolbar={this.hideToolbar} />} />
                                <Route path="/menu/:id" component={MenuDetail}/>
                                <Route path="/reset-password" component={ResetPassword}/>
                                <Route path="/invalid-link" component={InvalidLink}/>
                                <Route component={NotFoundPage}/>
                            </Switch>
                        </Router>

                    </Content>

                    <Footer style={{background: "black"}}>
                        <div className="container">
                            <p style={{color: "white", margin: "0", fontSize: "16px"}}>
                                © 2018 - 2020 Quiero Menú - MULTIAPPS SL
                            </p>
                        </div>
                    </Footer>
                </Layout>
            </Layout>
        );
    }
}


