import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "antd";

class GalleryMenu extends Component {
  constructor() {
    super();

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }
  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  gotoImage(index) {
    this.setState({
      currentImage: index
    });
  }
  handleClickImage() {
    if (this.state.currentImage === this.props.images.length - 1) return;

    this.gotoNext();
  }
  renderGallery() {
    const { images } = this.props;

    if (!images) return;

    const gallery = images.filter(i => true).map((obj, i) => {
      return (
        <a
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          href={obj.src}
          key={i}
          onClick={e => this.openLightbox(i, e)}
        >
          <img
            className={"gallery__image--detail"}
            style={{
              maxWidth: "350px",
              marginRight: "1em",
              marginBottom: "1em",
              flex: 1
            }}
            src={obj.src}
            e
          />
        </a>
      );
    });

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {gallery}
      </div>
    );
  }
  render() {
    return (
      <div className="section">
        {this.renderGallery()}
      </div>
    );
  }
}

GalleryMenu.displayName = "GalleryMenu";
GalleryMenu.propTypes = {
  heading: PropTypes.string,
  images: PropTypes.array,
  showThumbnails: PropTypes.bool,
  subheading: PropTypes.string
};

const gutter = {
  small: 2,
  large: 4
};

export default GalleryMenu;
