import * as React from "react";
import {Collapse} from 'antd';
import moment from "moment";
import FullSchedule from "./FullSchedule";

const {Panel} = Collapse;

export default class Schedule extends React.Component {
    constructor(props) {
        super(props);
        this.turns = {};
        this.state = {turns: {}, loading: true, mierda: true};
    }

    componentDidMount() {
        this.getTurns();
    }

    getHeader = () => {
        return (<div style={{position: 'relative'}}>


            {!this.state.loading && this.state.schedule ? (
                <div style={{display: 'flex'}}>
                    {this.state.turns[this.state.schedule.get("name")].map(turn => {
                        return (
                            <p style={{marginBottom: 0, color:'rgba(0, 0, 0, 0.65)'}} key={turn.id}>
                                {moment(turn.get("startDate"))
                                    .format("HH:mm")
                                    .toString() +
                                " - " +
                                moment(turn.get("endDate"))
                                    .format("HH:mm")
                                    .toString()}
                            </p>
                        );
                    })}
                </div>
            ) : <p style={{marginBottom: 0}}>{"Cerrado hoy"}</p>}
        </div>);
    }

    render() {
        return (
            <div>
                <div style={{
                    height: '1px',
                    width: '100%',
                    background: '#e1e4e8',
                    marginBottom: '1em'
                }}/>
                <p style={{marginBottom: 0}}><b style={{marginTop: "1em"}}>Horario</b></p>


                <Collapse
                    className={"scheduleCollapse"}
                    expandIconPosition={'right'}
                >
                    <Panel className={"schedulePanel"} header={this.getHeader()} key="1">
                        <FullSchedule schedules={this.props.schedules}/>
                    </Panel>
                </Collapse>
            </div>
        );
    }

    getTurns() {
        var schedules = this.props.schedules;
        schedules.forEach(schedule => {
            if (parseInt(schedule.get("order")) === moment().isoWeekday()) {
                this.getTurnsOfSchedule(schedule);
            }
        });
    }

    getTurnsOfSchedule = schedule => {
        this.setState({loading: true});
        let query = schedule.get("turn").query();
        query.find().then(turns => {
            if (turns.length > 0) {
                this.turns[schedule.get("name")] = [];
            }
            turns.forEach(turn => {
                this.turns[schedule.get("name")].push(turn);

                let turns = this.turns;
                this.setState({turns, loading: false, schedule: schedule});
            });
        });
    };
}
