import React, { Component } from "react";
import { Spin } from "antd";
import ItemListRestaurant from "./ItemListRestaurant";
var Parse = require("parse").Parse;

export default class RestaurantResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
    this.restaurants = [];

    this.getLocation();
  }

  render() {
    return (
      <div
          className={"container-grid-restaurants"}
        style={{
          marginTop: "2em",
          marginBottom: "2em",
          marginLeft: "1em",
          marginRight: "1em"
        }}
      >
        {this.state.loading ? (
          <div style={{ width: "100%", textAlign: "center", marginTop: "7em" }}>
            <Spin size="large" />
          </div>
        ) : (
          <div className={""}>
            <div className={"grid-restaurants"}>
              {this.restaurants.map(restaurant => {
                return (
                  <ItemListRestaurant
                    key={restaurant.id}
                    restaurant={restaurant}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.getRestaurants,
        this.showError
      );
    } else {
      //x.innerHTML = "Geolocation is not supported by this browser.";
    }
  };

  showError = error => {
    this.getRestaurants();
    switch (error.code) {
      case error.PERMISSION_DENIED:
        //  x.innerHTML = "User denied the request for Geolocation.";
        break;
      case error.POSITION_UNAVAILABLE:
        //x.innerHTML = "Location information is unavailable.";
        break;
      case error.TIMEOUT:
        //x.innerHTML = "The request to get user location timed out.";
        break;
      case error.UNKNOWN_ERROR:
        //x.innerHTML = "An unknown error occurred.";
        break;
    }
  };

  getRestaurants = position => {
    let location = undefined;
    let restaurants = this.restaurants;

    if (position) {
      location = new Parse.GeoPoint({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      });
    }

    let query = new Parse.Query("Restaurant");
    query.near("location", location);
    query.exists("mainImage");
    query.equalTo("hidden", false);
    query.limit(30);
    query.find().then(results => {
      restaurants.push.apply(restaurants, results);
      this.restaurants = restaurants;
      //      console.log(this.restaurants);
      this.setState({
        loading: false
      });
    });
  };
}
