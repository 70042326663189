import React, { Component, Suspense } from "react";
import { Card, Spin, Button } from "antd";
import { Link } from "react-router-dom";
import GalleryMenu from "../GalleryMenu";
import "./MenuDetail.less";

var Parse = require("parse").Parse;

export default class MenuDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurant: undefined,
      loading: true,
      notFound: false,
      title: "",
      shareUrl: "https://quieromenu.com/menu/" + this.props.match.params.id
    };
  }

  componentDidMount() {
    let restaurantId = this.props.match.params.id;
    this.getData(restaurantId);
  }

  render() {
    let shareUrl = this.state.shareUrl;

    return (
      <div>
        <Suspense fallback={<div>Cargando...</div>}>
          <div className="container">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2em",
                marginBottom: "4em"
              }}
            >
              {this.state.loading ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "7em"
                  }}
                >
                  <Spin size="large" />
                </div>
              ) : !this.state.notFound ? (
                <Card
                  style={{
                    marginRight: "1em",
                    marginLeft: "1em"
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    {this.getTitle()}

                    {this.state.restaurant ? (
                      <div style={{position:'fixed', bottom:0, left:0, width:'100%', background:'#fff', padding:'10px 0', display:'flex', justifyContent:'center'}}>
                        <Link
                          to={"/establecimiento/" + this.props.match.params.id}
                        >
                          <Button
                            style={{ marginTop: "0em",   }}
                            type="primary"
                            size={"large"}
                          >
                            Ver establecimiento
                          </Button>
                        </Link>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.restaurant.get("currentMenu").has("image") ? (
                      <div>
                        <GalleryMenu
                          images={[
                            {
                              src: this.state.restaurant
                                .get("currentMenu")
                                .get("image")
                                .url()
                            }
                          ]}
                        />
                      </div>
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        Ups! El menú todavía no está disponible en este
                        establecimiento
                      </p>
                    )}
                  </div>
                </Card>
              ) : (
                <NotFound />
              )}
            </div>
          </div>
        </Suspense>
      </div>
    );
  }

  getData = restaurantId => {
    let query = new Parse.Query("Restaurant");
    query.equalTo("objectId", restaurantId);
    query.include("currentMenu");
    query.first().then(restaurant => {
      if (!restaurant) {
        this.setState({ loading: false, notFound: true });
      }

      this.setState({
        loading: false,
        restaurant,
        title: "Mira que hay de menú en " + restaurant.get("establishmentName")
      });
    });
  };

  getTitle = () => {
    return (
      <div>
        <h1 className={"establishmentName"} style={{ marginBottom: "20px" }}>
          {this.state.restaurant.get("establishmentName")}
        </h1>
      </div>
    );
  };
}

const NotFound = () => {
  return (
    <div style={{ width: "100%", textAlign: "center", marginTop: "7em" }}>
      <p style={{ textAlign: "center" }}>
        Ups! No hemos encontrado este establecimiento
      </p>
      <Link to="/">
        <Button type="primary" size={"large"}>
          Volver
        </Button>
      </Link>
    </div>
  );
};
