import React, {Component} from "react";
import {Button, Row, Col} from "antd";
import Image from "./images/restaurant.jpg";
import ImagePlayStore from "./images/googleplay.png";
import ImageAppleStore from "./images/applestore.png";
import RestaurantResults from "./RestaurantResults";

export default class Home extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col span={24}>
                        <div
                            className={"home-slider"}
                            style={{
                                background: `url(${Image})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                width: "100%",
                                height: "450px"
                            }}
                        >
                            <div
                                className={'home-overlay'}
                                style={{
                                    height: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: "center",
                                        margin: "auto",
                                        height: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "100%",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginLeft: "0.5em",
                                                marginRight: "0.5em"
                                            }}
                                        >
                                            <h2 style={{textAlign: "center", color: "white"}}>
                                                <span style={{fontSize:'150%'}}className={"hideOnMobile"}>¿Donde comes hoy?</span>
                                                <span className={"hideOnDesktop"}>Sigue a tu establecimiento favorito</span>
                                            </h2>

                                            <div className={"hideOnMobile"}>
                                                <h3
                                                    className={"hideOnMobile"}
                                                    style={{
                                                        textAlign: "center",
                                                        color: "white",
                                                        maxWidth: "800px",
                                                        marginTop: "20px"
                                                    }}
                                                >
                                                    Podrás consultar los menús del día más cercanos,
                                                    añadirlos a tus favoritos y recibir una notificación en
                                                    cuanto los publiquen
                                                </h3>
                                                <h3

                                                    style={{
                                                        textAlign: "center",
                                                        color: "white",
                                                        maxWidth: "800px",
                                                        marginTop: "20px"
                                                    }}
                                                >
                                                </h3>
                                            </div>

                                            <div className={"hideOnDesktop"} style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                                            <p style={{color:'#fff', marginLeft:'20px', marginRight:'20px'}}>Consulta los menús del día cercanos,
                                                y recibe una notificación en
                                                cuanto los publiquen</p>

                                                <div style={{display:'flex'}}>
                                                    <a
                                                        href={
                                                            "https://play.google.com/store/apps/details?id=com.quieromenu.android.app"
                                                        }
                                                    >
                                                        <img
                                                            style={{maxWidth: "10em", marginRight: "10px"}}
                                                            src={ImagePlayStore}
                                                        />
                                                    </a>
                                                    <a
                                                        href={
                                                            "https://apps.apple.com/es/app/quieromen%C3%BA/id1488173660?ls=1"
                                                        }
                                                    >
                                                        <img style={{maxWidth: "10em"}} src={ImageAppleStore}/>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="grid qm-container">


                    <div className="col1">
                        <div
                            className={"card-follow-restaurant"}
                            style={{
                                padding: "2em",
                                background: "white",
                                marginTop: "2em",
                                marginBottom: "1.5em",
                                marginLeft: "1em",
                                marginRight: "1em",
                                borderRadius:'5px'
                            }}
                        >
                            <h2 style={{marginBottom: "15px"}}>
                                Sigue a tu establecimiento favorito
                            </h2>
                            <p>
                                Añade a <b>favoritos</b> tu establecimiento desde la APP de Quiero Menú
                                y te <b>notificaremos el menú del día</b> en cuanto el establecimiento
                                lo suba
                            </p>
                            <div
                                style={{
                                    maxWidth: "30em",
                                    marginTop: "20px",
                                    marginRight: "2em"
                                }}
                            >
                                <div style={{display:'flex'}}>
                                    <a
                                        href={
                                            "https://play.google.com/store/apps/details?id=com.quieromenu.android.app"
                                        }
                                    >
                                        <img
                                            style={{maxWidth: "10em", marginRight: "10px"}}
                                            src={ImagePlayStore}
                                        />
                                    </a>
                                    <a
                                        href={
                                            "https://apps.apple.com/es/app/quieromen%C3%BA/id1488173660?ls=1"
                                        }
                                    >
                                        <img style={{maxWidth: "10em"}} src={ImageAppleStore}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col2">
                        <div
                            className=""
                            style={{marginLeft: "auto", marginRight: "auto"}}
                        >
                            <RestaurantResults/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
