import React, {Component, Suspense} from "react";
import {Button, Card, Form, Input} from "antd";
import {CheckCircleOutlined} from '@ant-design/icons';

var Parse = require("parse").Parse;

export default class InvalidLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requested: false
        }
    }

    onFinish = values => {

        Parse.User.requestPasswordReset(values.email)
            .then(() => {
                this.setState({
                    requested: true
                });
            }).catch((error) => {
            //console.log(error);
        });
    };

    render() {
        return <div className={"qm-container"}>
            <Suspense fallback={<div>Cargando...</div>}>
                <Card style={{margin: '20px'}}>
                    {!this.state.requested ?
                        <div>
                            <h2 style={{marginBottom: 0, paddingBottom: 0}}>Enlace inválido</h2>
                            <p>El enlace que has utilizado ya no es válido, prueba a generar uno nuevo:</p>
                            <Form
                                name="register"
                                onFinish={this.onFinish}
                                scrollToFirstError>
                                <Form.Item
                                    style={{display: 'block'}}
                                    name="email"
                                    label="Email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Introduce un email válido',
                                        },
                                        {
                                            required: true,
                                            message: 'Por favor introduce un email',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item>
                                    <Button style={{marginTop: '20px'}} type="primary" block htmlType="submit">
                                        Recuperar contraseña
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div> : <RecoverPinRequested/>}
                </Card>
            </Suspense>
        </div>;
    }


}

const RecoverPinRequested = () => {
    return (
        <div style={{width: "100%", textAlign: "center",}}>
            <CheckCircleOutlined style={{fontSize: '80px', color: 'green', marginBottom: '20px'}}/>
            <h2>Te hemos enviado un email</h2>
            <p style={{textAlign: "center"}}>
                Revisa tu email, hemos enviado un correo para que puedas cambiar la contraseña, este puede tardar
                algunos minutos en llegar
            </p>
            <Button
                style={{
                    marginTop: "20px"
                }}
                href={"https://app.quieromenu.com"}
                type="primary"
            >
                Continuar
            </Button>
        </div>
    );
};