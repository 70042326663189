import PropTypes from "prop-types";
import React, {Component} from "react";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";

class GalleryList extends Component {
    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.gotoImage = this.gotoImage.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
    }

    openLightbox(index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1
        });
    }

    gotoImage(index) {
        this.setState({
            currentImage: index
        });
    }

    handleClickImage() {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }

    renderGallery() {
        const {images} = this.props;

        if (!images) return;

        const gallery = images.filter(i => true).map((obj, i) => {
            return (
                <a
                    className={"gallery__list--link"}
                    href={obj.src}
                    key={i}
                    onClick={e => this.openLightbox(i, e)}
                >
                    <img className={"gallery__list"} src={obj.src} e/>
                </a>
            );
        });

        return (
            <SimpleReactLightbox>
                <div className="section section__gallery__horizontal">
                    <SRLWrapper options={{
                        settings: {
                            hideControlsAfter: false
                        },
                        buttons: {
                            showAutoplayButton: false,
                            showThumbnailsButton: false
                        }
                    }}>
                        <div
                            className={"gallery__horizontal"}
                            style={{display: "flex", flexWrap: "wrap"}}
                        >
                            {gallery}
                        </div>
                    </SRLWrapper>
                </div>
            </SimpleReactLightbox>
        );
    }

    render() {
        return (
            <div>{this.renderGallery()}</div>
        );
    }
}

GalleryList.displayName = "GalleryList";
GalleryList.propTypes = {
    heading: PropTypes.string,
    images: PropTypes.array,
    showThumbnails: PropTypes.bool,
    subheading: PropTypes.string
};

export default GalleryList;
