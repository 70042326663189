import React, {Component} from "react";
import {Link} from "react-router-dom";

export default class ItemListRestaurant extends Component {
    render() {
        return (
            <div>
                <Link to={"/establecimiento/" + this.props.restaurant.id}>
                    {this.props.restaurant.get("thumbnail") ? (
                        <div
                            className={"item__restaurant"}
                            style={{
                                backgroundImage: `url(${this.props.restaurant
                                    .get("thumbnail")
                                    .url()})`,
                                backgroundPosition: "center",
                                width: "100%",
                                height: "200px",
                                backgroundSize: "cover",
                                position: "relative"
                            }}
                        >
                            <div
                                style={{
                                    zIndex: "1",
                                    position: "absolute",
                                    left: "0",
                                    top: "0",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "10px"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        height: "100%",
                                        flexDirection: "column",
                                        width: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "flex-end"
                                        }}
                                    >
                                        <div
                                            style={{
                                                borderRadius:"5px",
                                                background: "#0000005e",
                                                width: "100%",
                                                padding: "15px 5px"
                                            }}
                                        >
                                            <h3
                                                style={{
                                                    marginLeft: "10px",
                                                    position: "relative",
                                                    zIndex: "1000",
                                                    color: "white",
                                                    marginBottom: 0
                                                }}
                                            >
                                                {this.props.restaurant.get("establishmentName")
                                                    ? this.props.restaurant.get("establishmentName")
                                                    : ""}
                                            </h3>
                                        </div>
                                    </div>

                                    {this.props.restaurant.get("hasMenu") ? (
                                        <div
                                            style={{
                                                background: "#E01C26",
                                                fontWeight: "bold",
                                                color: "white",
                                                width: "100%",
                                                zIndex: 1000,
                                                padding: "2px 5px",
                                                borderBottomLeftRadius: "5px",
                                                borderBottomRightRadius: "5px"
                                            }}
                                        >
                                            <span style={{marginLeft: "10px", fontWeight: 'bold'}}>Publicó menú</span>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                {this.props.restaurant.get("price") > 0 ? (
                                    <div
                                        style={{
                                            padding: "5px 10px",
                                            zIndex: "1000",
                                            color: "white",
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            background: "#E01C26",
                                            display: "inline",
                                            margin: "0.5em",
                                            borderRadius: "5px"
                                        }}
                                    >
                                        <p style={{fontSize: "15px", marginBottom: 0}}>
                                            {this.props.restaurant.get("price")
                                                ? this.props.restaurant.get("price") + " €"
                                                : ""}
                                        </p>
                                    </div>
                                ) : (
                                    ""
                                )}


                                {this.props.restaurant.has("bono")  ? (
                                    <div
                                        style={{
                                            padding: "5px 8px",
                                            zIndex: "1000",
                                            color: "white",
                                            position: "absolute",
                                            bottom: 0,
                                            right: 0,
                                            display: "inline",
                                            margin: "0.5em",
                                            borderRadius: "5px",
                                            border: '1px dashed white'
                                        }}
                                    >
                                        <p className={"font-caveat"} style={{fontSize: "18px", marginBottom: 0, color:'white',position:'relative', left:'-2px'}}>M</p>
                                    </div>
                                ) : (
                                    ""
                                )}

                            </div>
                        </div>
                    ) : (
                        "cargando"
                    )}
                </Link>
            </div>
        );
    }
}
