import * as React from "react";
import moment from "moment";

export default class FullSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.turns = {};
        this.state = {turns: {}, loading: true};
    }

    componentDidMount() {
        this.getTurns();
    }

    render() {
        return (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {this.props.schedules.map(schedule => {
                    return (
                        <div key={schedule.id} style={{marginRight: '20px'}}>
                            <div style={{marginBottom: "20px"}}>
                                <p style={{display: "inline-block", marginBottom: 0, fontWeight: 'bold'}}>
                                    {schedule.get("name")}
                                </p>
                                {this.state.turns[schedule.get("name")] &&
                                !this.state.loading ? (
                                    <div>
                                        {this.state.turns[schedule.get("name")].map(turn => {
                                            return (
                                                <p key={turn.id} className={"horary__label"}>
                                                    {moment(turn.get("startDate"))
                                                        .format("HH:mm")
                                                        .toString() +
                                                    " - " +
                                                    moment(turn.get("endDate"))
                                                        .format("HH:mm")
                                                        .toString()}
                                                </p>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div>{"Cerrado"}</div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    getTurns() {
        var schedules = this.props.schedules;
        schedules.forEach(schedule => {
            this.getTurnsOfSchedule(schedule);
        });
    }

    getTurnsOfSchedule = schedule => {
        this.setState({loading: true});
        let query = schedule.get("turn").query();
        var turnos = this.turns;
        query.find().then(turns => {
            if (turns.length > 0) {
                this.turns[schedule.get("name")] = [];
            }
            turns.forEach(turn => {
                this.turns[schedule.get("name")].push(turn);

                let turns = this.turns;
                this.setState({turns, loading: false});
            });
        });
    };
}
