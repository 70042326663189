import React, {Component, Suspense} from "react";
import {Card, Spin, Button, Select, Layout, Carousel, Row, Col} from "antd";
import {Link} from "react-router-dom";
import Schedule from "./Schedule";
import GalleryList from "./GalleryList";
import ImagePlayStore from "./images/googleplay.png";
import ImageAppleStore from "./images/applestore.png";
import {isAndroid} from 'react-device-detect';
import {isIOS} from 'react-device-detect';
import {
    RightOutlined,
} from "@ant-design/icons";

const {Header} = Layout;
var Parse = require("parse");
const {Option} = Select;

export default class RestaurantDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            restaurant: undefined,
            menuBookImages: undefined,
            galleryImages: undefined,
            schedules: undefined,
            turnos: {},
            loading: true,
            notFound: false,
            menu: undefined,
            dishes: undefined,
            title: "",
            cupon: undefined,
            shareUrl:
                "https://quieromenu.com/establecimiento/" + this.props.match.params.id,
            languages: [
                {'es': 'Español'}
            ],
            languageSelected: 'es'
        };


    }

    componentDidMount() {
        let restaurantId = this.props.match.params.id;
        this.getDatos(restaurantId);

        this.props.hideToolbar();

    }

    render() {
        return (
            <div className={"restaurant"}>
                <Suspense fallback={<div>Cargando...</div>}>
                    <div style={{marginBottom: '60px'}}>
                        <div>
                            {this.state.loading ? (
                                <div
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        marginTop: "7em"
                                    }}
                                >
                                    <Spin size="large"/>
                                </div>
                            ) : this.state.notFound ? (
                                <div>
                                    <div
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            marginTop: "7em"
                                        }}
                                    >
                                        <Spin size="large"/>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <Header
                                        style={{
                                            textAlign: "center",
                                            backgroundColor: "#E01C2C",
                                            zIndex: 1,
                                            width: "100%",
                                        }}
                                    >
                                        <a
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%"
                                            }}
                                            href={"/"}
                                        >
                                            <h1
                                                className={"establishmentName"}
                                                style={{
                                                    display: "inline-block"
                                                }}
                                            >
                                                {this.state.restaurant.get("establishmentName")}
                                            </h1>

                                        </a>
                                    </Header>

                                    <div className={"container"}>


                                        <Row gutter={[10, 10]} className={"restaurant-grid"}>
                                            <Col lg={12} md={24} style={{width:"100%"}}>
                                                <Carousel style={{width:"100%"}} autoplay>
                                                    {this.state.restaurant && this.state.restaurant.has("mainImage") ? (
                                                        <img className={"image-corousel"} src={this.state.restaurant
                                                            .get("mainImage")
                                                            .url()}/>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {this.state.galleryImages && this.state.galleryImages.length > 0 ? this.state.galleryImages.map(galleryImage => {
                                                        return (<img className={"image-corousel"}
                                                                     src={galleryImage.get("image").url()}/>);
                                                    }) : ''}

                                                </Carousel>

                                                {isAndroid && this.state.restaurant && this.state.restaurant.get("hasTableOrders") ?
                                                    <div style={{display: 'flex', margin: '10px 10px 0 10px'}}>
                                                        <Button
                                                            href="https://play.google.com/store/apps/details?id=com.quieromenu.android.app"
                                                            type="primary"
                                                            style={{flex: 1, width: '100%', marginRight: '2px'}}>Llamar
                                                            al camarero</Button>
                                                        <Button
                                                            href="https://play.google.com/store/apps/details?id=com.quieromenu.android.app"
                                                            type="primary"
                                                            style={{flex: 1, width: '100%', marginLeft: '2px'}}>Pedir la
                                                            cuenta</Button>
                                                    </div> : ''}

                                                    {isIOS && this.state.restaurant && this.state.restaurant.get("hasTableOrders") ?
                                                    <div style={{display: 'flex', margin: '10px 10px 0 10px'}}>
                                                        <Button
                                                            href="https://apps.apple.com/es/app/quieromen%C3%BA/id1488173660?ls=1"
                                                            type="primary"
                                                            style={{flex: 1, width: '100%', marginRight: '2px'}}>Llamar
                                                            al camarero</Button>
                                                        <Button
                                                            href="https://apps.apple.com/es/app/quieromen%C3%BA/id1488173660?ls=1"
                                                            type="primary"
                                                            style={{flex: 1, width: '100%', marginLeft: '2px'}}>Pedir la
                                                            cuenta</Button>
                                                    </div> : ''}

                                                <Row>

                                                    {this.state.menuBookImages &&
                                                    this.state.menuBookImages.length > 0 ? (
                                                        <Card
                                                            style={{
                                                                marginTop: '10px',
                                                                width: '100%',
                                                                borderRadius:'5px'
                                                            }}
                                                        >
                                                            <h2
                                                                style={{
                                                                    borderBottom: "3px solid #e01c2c",
                                                                    display: "inline-flex",
                                                                    fontWeight: 'bold'
                                                                }}
                                                            >
                                                                La carta
                                                            </h2>

                                                            {this.state.languages ? (
                                                                <div
                                                                    style={{
                                                                        padding: "5px 10px",
                                                                        zIndex: "1000",
                                                                        color: "white",
                                                                        position: "absolute",
                                                                        top: 0,
                                                                        right: 0,

                                                                        display: "inline",
                                                                        margin: "0.5em",
                                                                        borderRadius: "5px"
                                                                    }}>
                                                                    <Select defaultValue="Español"
                                                                            style={{width: 120}}
                                                                            onChange={this.handleSelectMenubookLanguage}>
                                                                        {
                                                                            Object.entries(this.state.languages).map(([key, value]) => {
                                                                                return <Option
                                                                                    value={key}>{value}</Option>;
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flex: "1",
                                                                    flexWrap: "wrap",
                                                                    marginTop: "20px"
                                                                }}
                                                            >
                                                                {this.state.restaurant && this.state.restaurant.has('currentMenu') ?
                                                                    <GalleryList
                                                                        images={
                                                                            [
                                                                                ...[{src: this.state.restaurant.get('currentMenu').get('image').url()}],
                                                                                ...this.state.menuBookImages.filter(menubook => menubook.get("language") === this.state.languageSelected).map(menubook => {
                                                                                    return {src: menubook.get("file").url()};
                                                                                })
                                                                            ]}
                                                                    /> : <GalleryList
                                                                        images={
                                                                            this.state.menuBookImages.filter(menubook => menubook.get("language") === this.state.languageSelected).map(menubook => {
                                                                                return {src: menubook.get("file").url()};
                                                                            })
                                                                        }
                                                                    />}
                                                            </div>
                                                        </Card>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Row>


                                            </Col>
                                            <Col lg={12} md={24} style={{width: '100%'}}>
                                                <Card
                                                    style={{
                                                        width: '100%',
                                                        borderRadius:'5px',
                                                        marginTop:'-1px'
                                                    }}
                                                >
                                                    <h2
                                                        style={{
                                                            borderBottom: "3px solid #e01c2c",
                                                            display: "inline-flex",
                                                            marginBottom: "20px",
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        Sobre el establecimiento
                                                    </h2>

                                                    {this.state.restaurant.get("motto").length > 0 || this.state.restaurant.get("establishmentType").length > 0 ? (
                                                        <div>
                                                            <p style={{marginBottom: 0}}><b style={{marginTop: "1em"}}>Tipo
                                                                de
                                                                cocina</b></p>
                                                            <p>{this.convertTypeToTypeString(this.state.restaurant.get("establishmentType"))} | {this.state.restaurant.get("motto")}</p>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {this.state.restaurant.has("phone") ?
                                                        <div style={{marginTop: "0.5em", position: 'relative'}}>
                                                            <div style={{
                                                                height: '1px',
                                                                width: '100%',
                                                                background: '#e1e4e8',
                                                                marginBottom: '1em'
                                                            }}/>
                                                            <p style={{marginBottom: 0}}><b>Teléfono</b></p>
                                                            <p>{this.state.restaurant.get("phone")}</p>
                                                            <div style={{position: 'absolute', right: 0, top: '45%'}}>
                                                                <RightOutlined
                                                                    style={{fontSize: '18px'}}/></div>

                                                            <a style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0
                                                            }}
                                                               href={"tel:" + this.state.restaurant.get("phone")}/>

                                                        </div> : ''}

                                                    <div style={{marginTop: "1em", position: 'relative'}}>

                                                        <div style={{
                                                            height: '1px',
                                                            width: '100%',
                                                            background: '#e1e4e8',
                                                            marginBottom: '1em'
                                                        }}/>
                                                        <p style={{marginBottom: 0}}><b>Dirección</b></p>
                                                        <p>{this.state.restaurant.get("address")}</p>
                                                        <div style={{position: 'absolute', right: 0, top: '45%'}}>
                                                            <RightOutlined
                                                                style={{fontSize: '18px'}}/></div>
                                                        <a
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0
                                                            }}
                                                            href={
                                                                "http://maps.google.com/maps?z=12&t=m&q=loc:" +
                                                                this.state.restaurant.get("location").latitude +
                                                                "+" +
                                                                this.state.restaurant.get("location").longitude
                                                            }/>
                                                    </div>

                                                    {this.state.schedules && this.state.schedules.length > 0 ? (
                                                        <Schedule
                                                            schedules={this.state.schedules}
                                                            turnos={this.state.turnos}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}

                                                    <div style={{marginTop: "1em"}}>
                                                        <div style={{
                                                            height: '1px',
                                                            width: '100%',
                                                            background: '#e1e4e8',
                                                            marginBottom: '1em'
                                                        }}/>
                                                        <p style={{marginBottom: 0}}><b
                                                            style={{marginTop: "1em"}}>Otros</b>
                                                        </p>

                                                        {this.state.restaurant.get("hasWifi") ? (
                                                            <p style={{marginBottom: 0}}>Tiene wifi</p>
                                                        ) : (
                                                            <p style={{marginBottom: 0}}>No tiene wifi</p>
                                                        )}
                                                    </div>

                                                    <div>
                                                        {this.state.restaurant.has("cheques") && this.state.restaurant.get("cheques").length>0 ? (
                                                            <p style={{marginBottom: 0}}>{this.getCheques()}</p>
                                                        ) : (
                                                            <p style={{marginBottom: 0}}>No acepta cheques</p>
                                                        )}
                                                    </div>

                                                    {this.state.restaurant.has("price") && this.state.restaurant.get("price") > 0 ? (
                                                        <div style={{marginTop: "1em"}}>
                                                            <div style={{
                                                                height: '1px',
                                                                width: '100%',
                                                                background: '#e1e4e8',
                                                                marginBottom: '1em'
                                                            }}/>
                                                            <p style={{marginBottom: 0}}><b>Precio del menú</b></p>
                                                            <p>{this.formatPrice(this.state.restaurant)} </p>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Card>

                                            </Col>
                                        </Row>


                                        {this.state.dishes && this.state.dishes.length > 0 ? (
                                            <Card
                                                style={{
                                                    width: '100%',
                                                    marginTop: '10px',
                                                    borderRadius:'5px'
                                                }}
                                            >
                                                <h2
                                                    style={{
                                                        borderBottom: "3px solid #e01c2c",
                                                        display: "inline-flex",
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    Especialidades
                                                </h2>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flex: "1",
                                                        flexWrap: "wrap",
                                                        marginTop: "20px",
                                                        overflowX: 'scroll'
                                                    }}
                                                >
                                                    <GalleryList
                                                        images={this.state.dishes.map(dishes => {
                                                            return {src: dishes.get("image").url()};
                                                        })}
                                                    />
                                                </div>
                                            </Card>
                                        ) : (
                                            ""
                                        )}

                                        {this.state.cupon ? (
                                            <Card
                                                style={{
                                                    width: '100%',
                                                    marginTop: '10px',
                                                    borderRadius:'5px'
                                                }}
                                            >
                                                <div>
                                                    <h2
                                                        style={{
                                                            borderBottom: "3px solid #e01c2c",
                                                            display: "inline-flex",

                                                        }}
                                                    >
                                                        <span style={{fontWeight: 'bold'}}>Cupones</span>
                                                    </h2>
                                                </div>

                                                <div>
                                                    <p>Este establecimiento premia a sus clientes al
                                                        conseguir {this.state.cupon.get("neededCoupons")} cupones
                                                        con:</p>
                                                </div>

                                                <div>
                                                    <h3
                                                        style={{
                                                            display: "inline-flex",
                                                            marginBottom: "0,5em",
                                                            fontWeight: 'bold',
                                                            color: '#E01C26'
                                                        }}
                                                    >
                                                        {this.state.cupon.get("title")}
                                                    </h3>
                                                </div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flex: "1",
                                                        flexWrap: "wrap",
                                                        marginTop: "0px"
                                                    }}
                                                >

                                                    <div>
                                                        <a
                                                            href={
                                                                "https://play.google.com/store/apps/details?id=com.quieromenu.android.app"
                                                            }
                                                        >
                                                            <img
                                                                style={{maxWidth: "10em", marginRight: "10px"}}
                                                                src={ImagePlayStore}
                                                            />
                                                        </a>
                                                        <a
                                                            href={
                                                                "https://apps.apple.com/es/app/quieromen%C3%BA/id1488173660?ls=1"
                                                            }
                                                        >
                                                            <img style={{maxWidth: "10em"}} src={ImageAppleStore}/>
                                                        </a>
                                                        <p style={{fontSize: '15px'}}>*Sella tus cupones con la app</p>
                                                    </div>


                                                </div>
                                            </Card>
                                        ) : (
                                            ""
                                        )}

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {isAndroid && this.state.restaurant && this.state.restaurant.get("hasTableOrders") ?
                        <div className={"btn_order"}>
                            <a style={{color: '#fff'}}
                               href={"https://play.google.com/store/apps/details?id=com.quieromenu.android.app"}>Pedir a
                                la barra</a>
                        </div> : ''}

                        {isIOS && this.state.restaurant && this.state.restaurant.get("hasTableOrders") ?
                        <div className={"btn_order"}>
                            <a style={{color: '#fff'}}
                               href={"https://apps.apple.com/es/app/quieromen%C3%BA/id1488173660?ls=1"}>Pedir a
                                la barra</a>
                        </div> : ''}

                        

                </Suspense>
            </div>
        );
    }

    getDatos = async restaurantId => {
        let query = new Parse.Query("Restaurant");
        query.equalTo("objectId", restaurantId);
        query.include('bono');
        query.include('currentMenu');
        let restaurant = await query.first();
        if (restaurant) {
            this.setState({
                loading: false,
                restaurant,
                title: "Mira el establecimiento " + restaurant.get("establishmentName")
            });
            let relation = restaurant.relation("menuBook");
            this.getMenuBookImages(relation);
            let relationImages = restaurant.relation("gallery");
            this.getGalleryImages(relationImages);
            let relationSchedule = restaurant.relation("schedule");
            this.getSchedules(relationSchedule);
            let relationDishes = restaurant.relation("dishes");
            this.getDishes(relationDishes);
            this.state.cupon = restaurant.get("bono");
        } else {
            this.setState({
                notFound: true,
                loading: false
            });
        }
    };

    getMenuBookImages = async relation => {
        let query = relation.query();
        let images = await query.find();
        let languages = {};
        images.forEach(carta => {
            if (carta.get('language') === 'py') return;
            languages[carta.get('language')] = Languages[carta.get('language') || "es"];
        });

        this.setState({
            menuBookImages: images,
            languages: languages
        });
    };

    getGalleryImages = async relation => {
        let query = relation.query();
        let galleryImages = await query.find();
        this.setState({
            galleryImages
        });
    };

    getSchedules = relation => {
        let query = relation.query();
        query.ascending("order");
        query.include("turn");
        return query.find().then(schedules => {
            this.setState({schedules});
        });
    };

    getDishes = relation => {
        let query = relation.query();
        query.find().then(dishes => {
            this.setState({
                dishes
            });
        });
    };

    handleSelectMenubookLanguage = (value) => {

        console.log("on lang selected");
        console.log(value);

        this.setState({
            languageSelected: value
        })
    }

    convertTypeToTypeString = (type) => {
        let text = EstablishmentType[type];
        if (text) {
            return text;
        } else return type;
    }

    formatPrice = (restaurant) => {
        let isSpanish = this.state.restaurant.has('country') && this.state.restaurant.get('country') === "es";
        if (isSpanish) {
            return this.state.restaurant.get("price") + "€";
        } else {
            return "$" + this.state.restaurant.get("price");
        }
    }

    getCheques = () =>{
        let text = "Se aceptan cheques: ";
        this.state.restaurant.get('cheques').forEach(cheque =>{
            if(cheque==='amipass'){
                text+="Amipass, ";
            }
            if(cheque==='sodexo'){
                text+="Sodexo, ";
            }
            if(cheque==='chequeGourmet'){
                text+="Cheque Gourmet, ";
            }
            if(cheque==='ticketRestaurant'){
                text+="Ticket Restaurant, ";
            }
            if(cheque==='other'){
                text+="Otros, ";
            }
        });
        return text.substring(0, text.length - 2);
    }
}


let Languages = {
    "es": "Español",
    "en": "Inglés",
    "pt": "Portugués",
    "de": "Alemán",
    "it": "Italiano",
    "fr": "Francés",
};

let EstablishmentType = {
    "restaurant": "Restaurante",
    "coffee-shop": "Cafetería",
    "cantine": "Taberna",
    "bar": "Bar",
    "meson": "Mesón",
    "fast-food": "Comida Rápida",
    "untyped": "",
};


const NotFound = () => {
    return (
        <div style={{width: "100%", textAlign: "center", marginTop: "7em"}}>
            <p style={{textAlign: "center"}}>
                Ups! No hemos encontrado este establecimiento
            </p>
            <Link to="/">
                <Button type="primary" size={"large"}>
                    Volver
                </Button>
            </Link>
        </div>
    );
};
