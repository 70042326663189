import React, {Suspense} from "react";
import {Result, Button} from 'antd';
import {Link} from "react-router-dom";

export const NotFoundPage = () => (
    <Suspense fallback={<div>Cargando...</div>}>
        <Result
            status="404"
            title="No hay resultados"
            subTitle="Lo sentimos, esta página no existe."
            extra={<Link to={"/"}><Button type="primary">Continuar</Button></Link>}
        />
    </Suspense>
);